import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { CHANGE_FORGOT_PASSWORD } from "graphql/mutation/user-gql";

import { errorNotification, successNotification } from "components/request-result";
import { FloatLabel } from "components/service";


const ChangeForgotPasswordForm = ({ forgotToken, formItemLayout }) => {

    const [ form ] = Form.useForm();
    const history = useHistory();

    const [changeForgotRequest, { loading }] = useMutation( CHANGE_FORGOT_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    userUpdateForgotPassword : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                    duration: 8
                });

                history.push("/login/");
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <div className="form-holder forgot-password change">
            <h1>Neues Passwort eingeben</h1>
            <p>Bitte geben Sie Ihr neues Passwort zweimal ein, damit sichergestellt ist, dass es korrekt eingegeben wurde.</p>
            <Form
                className="change-forgot-password-form"
                form={ form }
                onFinish={
                    (values) => {

                        changeForgotRequest({
                            variables : {
                                password: values.password,
                                token: forgotToken
                            }
                        }).catch((error) => {
                            errorNotification(error);
                        });
                    }
                }
            >
                <Form.Item
                    {...formItemLayout}
                    name="password"
                    rules={[
                        { min: 8, message: 'Password must be at least 8 characters' },
                        { required: true, message: 'Please input your Password' }
                    ]}
                >
                    <FloatLabel label="Passwort*">
                        <Input.Password name="password"/>
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name="confirm-password"
                    rules={[
                        { required: true, message: 'Please confirm your password' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        })
                    ]}
                >
                    <FloatLabel label="Passwort bestätigen*">
                        <Input.Password name="confirm-password"/>
                    </FloatLabel>
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loading }
                        >
                            ABSENDEN
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            <div className="row bordered-top">
                <p>Haben Sie sich Ihr Passwort schon gemerkt?</p>
                <Link to="/login/" className="link-text-bold">Jetzt einloggen</Link>
            </div>
        </div>
    );
};

export default ChangeForgotPasswordForm;