import React, { useState } from "react";
import { Modal } from "antd";

import Icons from "components/icons";


const ModalStandard = ({
        modalButton,
        extraClass = "modal-dark",
        ...props
    }) => {

    const [ showModal, setShowModal ] = useState(false);

    return(
        <>
            { React.cloneElement( modalButton, { onClick : () =>  setShowModal(true)}) }
            <Modal
                className={ `modal-standard ${ extraClass }` }
                visible={ showModal }
                centered
                width={ "auto" }
                closeIcon={ <Icons.Cross /> }
                destroyOnClose={ true }
                footer={ null }
                onOk={() => setShowModal(false)}
                onCancel={() => {
                    setShowModal(false)
                }}
            >
                { Array.isArray(props.children) ?
                    props.children.map(
                        ( child, index ) =>  React.cloneElement( child, { key: index, action : () =>  setShowModal(false)})
                    ):
                    React.cloneElement( props.children, { action : () =>  setShowModal(false)})
                }

            </Modal>
        </>
    );
}

export default ModalStandard;

