import React from "react";
import { Button } from "antd";

import { Forms } from "components/user";
import Icons from "components/icons";
import { useMe } from "components/use-hooks";
import { ModalStandard } from "components/service";

import "./profile-page.scss";


const ProfilePage = () => {

    const { me } = useMe();

    return(
        <div className="page profile-page">
            <div className="container">
                <div className="block sml">
                    <div className="row">
                        <h1>Dein Profil</h1>
                    </div>
                    <div className="row user-info">
                        <div className="data">
                            <p>{ me.first_name } { me.last_name }</p>
                            <p>{ me.email }</p>
                            <p>{ me.phone }</p>
                        </div>
                        <div className="action">
                            <ModalStandard
                                modalButton={ <Button className="icon rounded"><Icons.Edit /></Button> }
                                extraClass={ "" }
                            >
                                <Forms.Profile
                                    me={ me }
                                />
                            </ModalStandard>
                        </div>
                    </div>
                    <div className="row">
                        <ModalStandard
                            modalButton={ <Button type="link">Passwort ändern</Button> }
                            extraClass={ "" }
                        >
                            <Forms.ChangePassword />
                        </ModalStandard>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilePage;