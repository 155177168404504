import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { CHANGE_PASSWORD } from "graphql/mutation/user-gql";

import { FloatLabel } from "components/service";
import { errorNotification, successNotification } from "components/request-result";



const UserChangePasswordForm = ({ action = () => {} }) => {

    const [ form ] = Form.useForm();

    const [changePasswordRequest, { loading }] = useMutation( CHANGE_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    userChangePassword : {
                        label,
                        message
                    }
                } = data;

                action();

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return(
        <>
            <h1>Passwort ändern</h1>
            <Form
                className="change-password-form"
                form={ form }
                onFinish={
                    (values) => {

                        changePasswordRequest({
                            variables : {
                                input: {
                                    password: values.password,
                                    new_password: values["new-password"],
                                    new_password_confirmation: values["confirm-password"]
                                }
                            }
                        }).catch((error) => {
                            errorNotification(error);
                        });
                    }
                }
            >
                <Form.Item
                    {...formItemLayout}
                    name="password"
                    rules={[
                        { required: true, message: 'Please input your old Password' }
                    ]}
                >
                    <FloatLabel label="Altes Passwort*">
                        <Input.Password name="password"/>
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name="new-password"
                    rules={[
                        { min: 8, message: 'Password must be at least 8 characters' },
                        { required: true, message: 'Please input new Password' }
                    ]}
                >
                    <FloatLabel label="Neues passwort*">
                        <Input.Password name="new-password"/>
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name="confirm-password"
                    rules={[
                        { required: true, message: 'Please confirm new password' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new-password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        })
                    ]}
                >
                    <FloatLabel label="Neues passwort bestätigen*">
                        <Input.Password name="confirm-password"/>
                    </FloatLabel>
                </Form.Item>
                <div className="form-actions">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={ loading }
                    >
                        ABSENDEN
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default UserChangePasswordForm;