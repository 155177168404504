import React from 'react';
import Icon from '@ant-design/icons';


const CutlerySvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 32 32" strokeWidth="1" >
        <path d="M8 0v8c0 1.657 1.343 3 3 3 1.598 0 2.904-1.249 2.995-2.824L14 8V0h2v8c0 2.419-1.717 4.436-3.999 4.9L12 32h-2V12.9c-2.214-.45-3.896-2.361-3.995-4.683L6 8V0h2zm4 0v7h-2V0h2zM25 .015c.513 0 .936.386.993.884l.007.116V32h-2V20.863l-.105-.028c-2.843-.87-4.816-5.054-4.896-10.016l-.002-.311C18.997 4.786 21.51.015 25 .015zm-1 18.688V2.312l-.025.014c-1.625.989-2.978 4.23-2.978 8.182 0 3.832 1.273 6.995 2.831 8.085l.172.11z" transform="translate(-316 -230) translate(316 230)"/>
    </svg>
);

const CutleryIcon = props => {
    return <Icon component={ CutlerySvg } className={ props.className } />;
};


export default CutleryIcon;