import React from "react";
import { Button, Form, Input } from "antd";

import { FloatLabel } from "components/service";
import { useVarParam } from "components/use-hooks";


const RegistrationLoginDataStep = ({ formItemLayout, submitAction = () => {} }) => {

    const [ form ] = Form.useForm();
    const guestParams = useVarParam("guest");

    const registration = guestParams.get("registration");


    return (
        <div className="registration-step">
            <h1>
                E-Mail eingeben und Passwort festlegen
            </h1>
            <Form
                className="registration-form"
                form={ form }
                onFinish ={(values) => {
                    guestParams.set({
                        registration : {
                            ...registration, ...values
                        }
                    })
                    submitAction();
                }}
            >
                <Form.Item
                    {...formItemLayout}
                    name="email"
                    initialValue={ registration.email ?? "" }
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please input your Email'
                        }
                    ]}
                >
                    <FloatLabel label="E-Mail">
                        <Input name="username" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name="password"
                    initialValue={ registration.password ?? "" }
                    rules={[
                        { min: 8, message: 'Password must be at least 8 characters' },
                        { required: true, message: 'Please input your Password' }
                    ]}
                >
                    <FloatLabel label="Passwort*">
                        <Input.Password name="password"/>
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name="confirm-password"
                    initialValue={ registration.password ?? "" }
                    rules={[
                        { required: true, message: 'Please confirm your password' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        })
                    ]}
                >
                    <FloatLabel label="Passwort bestätigen*">
                        <Input.Password name="confirm-password"/>
                    </FloatLabel>
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Weiter
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default RegistrationLoginDataStep;