import { gql } from '@apollo/client';


export const VISIT_CHECKIN = gql`
    mutation VisitCheckin($input: VisitInput!) {
        visitCheckin(input: $input){
            label
            message
            visit{
                id
                email
                user_id
                first_name
                last_name
                phone
                location_id
                place_id
                number_guests
                covid_id
                checkout_at
                created_at
            }
        }
    }
`;

export const VISIT_NOTICE_FORM_CREATE = gql`
    mutation NoticeFormCreate(
        $visitId: ID!
        $email: String!
        $message: String
    ) {
        noticeFormCreate(
            visit_id : $visitId
            email : $email
            message : $message
        ){
            label
            message
        }
    }
`;
