import { gql } from '@apollo/client';

export const GET_LOCATION = gql`
    query GetLocation(
        $id: ID
    ){
        location(
            id: $id
        ){
            id
            title
            company_id
            type
            status
            logo
            preview_banner
            latitude
            longitude
            slug
            qrcode
            meta_fields{
                id
                location_id
                meta_key
                meta_type
                meta_value
                meta_group
            }
            visitsPerMonth{
                count
                month
            }
            visitsDangerous
            start_at
            end_at
            created_at
            updated_at
        }
    }
`;