import LogoIcon from "./logo-icon";
import MenuIcon from "./menu-icon";
import CrossIcon from "./cross-icon";
import ArrowIcon from "./arrow-icon";
import FlagIcon from "./flag-icon";
import EmailIcon from "./email-icon";
import DocumentIcon from "./document-icon";
import TrashIcon from "./trash-icon";
import EditIcon from "./edit-icon";
import QrIcon from "./qr-icon";
import ClockIcon from "./clock-icon";
import PeoplesIcon from "./peoples-icon";
import CutleryIcon from "./cutlery-icon";
import DrinkIcon from "./drink-icon";
import ChefIcon from "./chef-icon";
import CalendarIcon from "./calendar-icon";


const Icons = {
    Logo     : LogoIcon,
    Menu     : MenuIcon,
    Cross    : CrossIcon,
    Arrow    : ArrowIcon,
    Flag     : FlagIcon,
    Document : DocumentIcon,
    Email    : EmailIcon,
    Trash    : TrashIcon,
    Edit     : EditIcon,
    Qr       : QrIcon,
    Clock    : ClockIcon,
    Peoples  : PeoplesIcon,
    Cutlery  : CutleryIcon,
    Drink    : DrinkIcon,
    Chef     : ChefIcon,
    Calendar : CalendarIcon
}

export default Icons;