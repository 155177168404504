import React from "react";

import AvatarImage from "../avatar-image";

import "./avatar-block.scss";


const AvatarBlock = ( {
      image,
      size,
      badge,
      className,
      shape = "circle", children } ) => {

  return(
      <div className={`avatar-block ${ className ?? "" }`}>
          {(image || badge) &&
              <AvatarImage
                  photoUrl={image}
                  size={size}
                  shape={shape}
                  badge={badge}
              />
          }
          <div className="avatar-content">
              { children }
          </div>
      </div>
  );

};

export default AvatarBlock;