import React from "react";
import { Link } from "react-router-dom";

import MenuDrawer from "../menu-drawer";
import MainMenu from "../main-menu";
import LegalMenu from "../legal-menu";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";
import { QrCodeModal } from "components/service";


const MainMenuGuestBar = () => {

    const guestParams = useVarParam("guest"),
          LeftControl = () => guestParams.get("leftHeaderControl"),
          RightControl = () => guestParams.get("rightHeaderControl");

    return (
        <header
            className={`main-menu-bar ${ guestParams.get().headerClass.join(" ") }`}
        >
            <div className="container">
                <div className="left-side">
                    <Link
                        to="/"
                        className="menu-button home"
                    >
                        <Icons.Logo className="logo" />
                    </Link>
                    <LeftControl />
                </div>
                <div className="right-side">
                    <RightControl />
                    <MenuDrawer
                        varParam={ guestParams }
                    >
                        <MainMenu.Guest key="guest-main-menu" />
                        <QrCodeModal
                            key="qr-modal"
                            successAction={ () => guestParams.set({ "menuDrawerOn" : false }) }
                        />
                        <LegalMenu  key="legal-menu"/>
                    </MenuDrawer>
                </div>
            </div>
        </header>
    );
};


export default MainMenuGuestBar;