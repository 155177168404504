import React from 'react';
import Icon from '@ant-design/icons';


const DocumentSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 32 32" strokeWidth="1" >
        <path d="M20.17 1c.73 0 1.433.266 1.979.745l.145.135 3.829 3.835c.515.516.823 1.2.87 1.922l.007.198V9h-2v-.001L19 9l-.001-6H7v26h18V11h2v20H5V1h15.17zm1.596 11.293l1.414 1.414-8.68 8.68-5.207-5.207 1.414-1.414L14.5 19.56l7.266-7.266zM21 3.414l.002 3.585h3.577l-3.579-3.585z" transform="translate(-40.000000, -272.000000) translate(40.000000, 272.000000)"/>
    </svg>
);

const DocumentIcon = props => {
    return <Icon component={ DocumentSvg } className={ props.className } />;

};


export default DocumentIcon;