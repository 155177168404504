import RegistrationIntroduceStep from "./registration-introduce-step";
import RegistrationLoginDataStep from "./registration-login-data-step";
import RegistrationLegalNoticeStep from "./registration-legal-notice-step";
import RegistrationSuccessStep from "./registration-success-step";


const RegistrationSteps = {
    UserIntroduce : RegistrationIntroduceStep,
    LoginData : RegistrationLoginDataStep,
    LegalNotice : RegistrationLegalNoticeStep,
    Success :  RegistrationSuccessStep
}

export default RegistrationSteps;