import React from "react";

import { QrCodeModal } from "components/service";
import { useMe } from "components/use-hooks";


const HomeAppPage = () => {

    const { me } = useMe();

    return(
        <div className="page home-page">
            <div className="container" >
                <div className="block sml">
                    <div className="row">
                        <h1>Hallo { me.first_name }, <br />
                            möchtest du jetzt deine Kontaktdaten im Restaurant angeben?
                        </h1>
                    </div>
                    <QrCodeModal />
                </div>
            </div>
        </div>
    )
};

export default HomeAppPage;