import React from 'react';
import Icon from '@ant-design/icons';


const DrinkSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 32 32" strokeWidth="1" >
        <path d="M16 1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-2.388 0-4.697-.56-6.776-1.614l-.399-.21-6.562 1.789c-.71.193-1.363-.415-1.252-1.117l.024-.111 1.79-6.56-.21-.399c-.989-1.95-1.543-4.101-1.608-6.331L1 16C1 7.716 7.716 1 16 1zm0 2C8.82 3 3 8.82 3 16c0 2.331.614 4.57 1.762 6.54.135.231.171.507.1.766l-1.437 5.269 5.271-1.437c.216-.058.443-.043.647.042l.12.06C11.43 28.386 13.669 29 16 29c7.18 0 13-5.82 13-13S23.18 3 16 3zm5 5v6c0 2.419-1.718 4.436-4 4.9V23h2v2h-6v-2h2v-4.1c-2.214-.45-3.896-2.361-3.995-4.683L11 14V8h10zm-2 2h-6v4c0 1.598 1.249 2.904 2.824 2.995L16 17c1.657 0 3-1.343 3-3v-4z" transform="translate(-316 -278) translate(316 278)"/>
    </svg>
);

const DrinkIcon = props => {
    return <Icon component={ DrinkSvg } className={ props.className } />;
};


export default DrinkIcon;