import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { AvatarBlock } from "components/avatar";



const VisitCheckinSuccessStep = ({ success, varParams, location, userType, locationPath }) => {

    useEffect(() => {
        return () => {

            varParams.set({
                checkin : {}
            })
        }
    }, [success, varParams]);

    return(
        <div className="visit-checkin-step success">
            <h1>Vielen Dank!</h1>
            <p>Wir haben deinen Besuch vermerkt und wünschen dir eine gute Zeit. </p>
            <AvatarBlock
                image={ location.logo }
                size={ 90 }
                className="visit-checkin-logo"
            >
                <p className="text-mono-bold">{ location.title }</p>
            </AvatarBlock>
            <div className="row">
                <Link
                    className="link-text-bold"
                    to={{
                        pathname : locationPath,
                        state: {
                            checkin: false,
                        }
                    }}
                >
                    Weitere Informationen zum Restaurant
                </Link>
            </div>
            { userType === "guest" &&
                <div className="row bordered-top">
                    <p>Möchtest du einen Account anlegen?</p>
                    <Link to="/registration/" className="link-button-primary">Bei loresta registrieren</Link>
                </div>
            }
        </div>
    )
}

export default VisitCheckinSuccessStep;