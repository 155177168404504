import React from "react";
import { useQuery } from "@apollo/client";

import { GET_VISITS } from "graphql/query/visit-gql";

import VisitListItem from "./visit-list-item";
import { Loader } from "components/request-result";


const VisitList = () => {

    const { data : { visits = {} } = {} , loading } = useQuery( GET_VISITS, {
        variables: {
            perPage : 50
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only"
    });

    console.log(visits);

    return(
        <div className="visit-list">
            { loading && <Loader /> }
            { !loading && visits && visits.data.map(
                visit =>  <VisitListItem key={ visit.id  } visit={ visit }  />)
            }
        </div>
    );
};

export default VisitList;