import React from 'react';
import Icon from '@ant-design/icons';


const FlagSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 32 32" strokeWidth="1" >
        <path d="M5 0v2h11v4h15c.774 0 1.24.832.876 1.483l-.06.096-3.856 5.422 3.855 5.42c.448.63.04 1.492-.701 1.573L31 20H14v-5h-.001L14 9.5V4H5v9h7l-.001 2H5v17H3V0h2zm11 18h13.062l-3.144-4.418c-.219-.308-.243-.71-.073-1.04l.073-.119L29.061 8H16v10z" transform="translate(-40.000000, -368.000000) translate(40.000000, 368.000000)"/>
    </svg>
);

const FlagIcon = props => {
    return <Icon component={ FlagSvg } className={ props.className } />;

};


export default FlagIcon;