import React from 'react';
import Icon from '@ant-design/icons';


const EditSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1" >
        <path d="M319.42 226.984a.75.75 0 0 1 1.06 0l3.536 3.536a.75.75 0 0 1 0 1.06l-12.021 12.021a.75.75 0 0 1-.303.185l-5.182 1.646a.75.75 0 0 1-.942-.942l1.646-5.182a.75.75 0 0 1 .185-.303zM308.377 240.6l-.941 2.964 2.963-.942-2.022-2.022zm8.307-8.76-7.472 7.473 2.474 2.474 7.473-7.472-2.475-2.475zm3.265-3.264-2.204 2.203 2.475 2.475 2.204-2.203-2.475-2.475z" transform="translate(-303 -224)" />
    </svg>
);

const EditIcon = props => {
    return <Icon component={ EditSvg } className={ props.className } />;

};


export default EditIcon;