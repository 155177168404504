import React from "react";
import { Link } from "react-router-dom";

import MainMenu from "../main-menu";
import LegalMenu from "../legal-menu";
import MenuDrawer from "../menu-drawer";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";
import { QrCodeModal } from "components/service";


const MainMenuAppBar = () => {

    const appParams = useVarParam(),
          LeftControl = () => appParams.get("leftHeaderControl"),
          RightControl = () => appParams.get("rightHeaderControl");

    return (
        <header
            className={`main-menu-bar ${ appParams.get().headerClass.join(" ") }`}
        >
            <div className="container">
                <div className="left-side">
                    <Link
                        to="/"
                        className="menu-button home"
                    >
                        <Icons.Logo className="logo" />
                    </Link>
                    <LeftControl />
                </div>
                <div className="right-side">
                    <RightControl />
                    <MenuDrawer
                        varParam={ appParams }
                    >
                        <MainMenu.App key="main-menu" />
                        <QrCodeModal
                            key="qr-modal"
                            successAction={ () => appParams.set({ "menuDrawerOn" : false }) }
                        />
                        <LegalMenu  key="legal-menu"/>
                    </MenuDrawer>
                </div>
            </div>
        </header>
    );
};


export default MainMenuAppBar;