import React from 'react';
import Icon from '@ant-design/icons';


const TrashSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1" >
        <path d="M318.015 621.5a1 1 0 0 1 .913.591l.041.11.562 1.799H325v1.5h-2.083l-.865 15.555a1 1 0 0 1-.998.945h-12.108a1 1 0 0 1-.998-.945l-.865-15.555H305V624h5.468l.563-1.798a1 1 0 0 1 .836-.695l.118-.007h6.03zm3.399 4h-12.829l.834 15h11.161l.834-15zm-8.164 2.5v10h-1.5v-10h1.5zm5 0v10h-1.5v-10h1.5zm-.604-5h-5.293l-.313 1h5.919l-.313-1z" transform="translate(-303 -620)" />
    </svg>
);

const TrashIcon = props => {
    return <Icon component={ TrashSvg } className={ props.className } />;

};


export default TrashIcon;