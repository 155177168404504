import Auth from "./auth";
import AuthConfirmAccount from "./auth-confirm-account";
import Forms from "./forms";


export {
    Forms,
    AuthConfirmAccount
};

export default Auth;