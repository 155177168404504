import React from "react";
import { useRouteMatch } from "react-router-dom";

import { Forms, AuthConfirmAccount } from "components/auth";


const RegistrationPage = () => {

    const match = useRouteMatch( "/registration/:token");

    return(
        <div className="page">
            <div className="container">
                <div className="block">
                    { match ?
                        <AuthConfirmAccount token={ match.params.token } />:
                        <Forms.Registration />
                    }
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;