import React from "react";
import { Button, Form, Input } from "antd";

import { FloatLabel } from "components/service";


const VisitCheckinContactStep = ({ formItemLayout, varParams, submitAction = () => {} }) => {

    const [ form ] = Form.useForm();

    const checkin = varParams.get("checkin");

    const {
        email = "",
        phone = ""
    } = checkin;

    return (
        <div className="visit-checkin-step">
            <h1>
                Deine E-Mail-Adresse und Telefonnummer
            </h1>
            <Form
                className="checkin-form"
                form={ form }
                onFinish ={(values) => {
                    varParams.set({
                        checkin : {
                            ...checkin, ...values
                        }
                    })
                    submitAction();
                }}
            >
                <Form.Item
                    {...formItemLayout}
                    name="email"
                    initialValue={ email }
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please input your Email'
                        }
                    ]}
                >
                    <FloatLabel label="E-Mail*">
                        <Input name="email" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    name="phone"
                    initialValue={ phone }
                    {...formItemLayout}
                >
                    <FloatLabel label="Telefonnummer">
                        <Input name="phone_number" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Weiter
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default VisitCheckinContactStep;