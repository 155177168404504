import React from "react";
import { Link } from "react-router-dom";


const MainMenuGuest = ( { action } ) => {

    return(
        <nav className="main-menu-wrap guest">
            <ul className="main-menu">
                <li>
                    <a href="https://loresta.de/">Über Loresta</a>
                </li>
                <li>
                    <a href="https://loresta.de/faq">FAQ</a>
                </li>
                <li>
                    <Link to="/registration/" onClick={ action }>Registrieren</Link>
                </li>
                <li>
                    <Link to="/login/" onClick={ action }>Anmelden</Link>
                </li>
            </ul>
        </nav>
    );
}

export default MainMenuGuest;