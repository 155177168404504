import { useHistory } from "react-router-dom";
import useVarParam from "./use-var-param";


const useAuthRedirect = () => {

    const history = useHistory(),
          guestParams = useVarParam("guest"),
          comeFrom = guestParams.get("comeFrom");

    if( comeFrom ){

        if( typeof comeFrom === "object"){
            return () => history.replace( comeFrom.path, {...comeFrom });
        } else {
            return () => history.replace( comeFrom );
        }

    }

    return () => history.replace("/");
}

export default useAuthRedirect;