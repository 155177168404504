import React from "react";

import "./legal-menu.scss";


const LegalMenu = () => {
    return(
        <div className="legal-menu-wrap" >
            <ul className="legal-menu">
                <li>
                    <a href="https://loresta.de/domainde/impressum">Impressum</a>
                </li>
                <li>
                    <a href="https://loresta.de/domainde/datenschutzerklaerung">Datenschutz</a>
                </li>
            </ul>
        </div>
    );
};

export default LegalMenu;