import React from "react";


import VisitCheckinAuth from "./visit-checkin-auth";
import Forms from "../forms";


const VisitCheckin = ({ location, locationInfo }) => {

    return(
        <div className="visit-checkin">
            <VisitCheckinAuth
                locationInfo={ locationInfo }
            >
                <Forms.Checkin
                    location={ location }
                    locationPath = { locationInfo.path }
                />
            </VisitCheckinAuth>
        </div>
    );
};


export default VisitCheckin;