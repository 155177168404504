import React from "react";
import { useLocation } from "react-router-dom";

import { Forms } from "components/auth";
import { useVarParam } from "components/use-hooks";


const LoginPage = () => {

    const location = useLocation(),
          guestParams = useVarParam("guest");

    if(location.state){
        if(location.state.saveState){
            guestParams.set({
                "comeFrom" : {
                    ...location.state
                }}
            );
        } else {
            guestParams.set({ "comeFrom" : location.state.path } );
        }

    }

    return(
        <div className="page">
            <div className="container">
                <div className="block">
                    <Forms.SignIn />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;