import React from "react";
import { Switch, Route } from 'react-router-dom';

import { Pages } from "components/pages";


const AppRoutes = () => {

    return(
        <Switch>
            <Route path="/" exact>
                <Pages.HomeAppPage />
            </Route>
            <Route path="/visits/" exact>
                <Pages.VisitsPage   />
            </Route>
            <Route path="/profile/" exact>
                <Pages.ProfilePage   />
            </Route>
            <Route path="/location/:locationSlug" exact>
                { ({ match, history, location }) => {

                    return (
                        <Pages.LocationPage
                            history={ history }
                            locationInfo={{
                                slug : match.params.locationSlug,
                                state: location.state,
                                path : location.pathname
                            }}
                        />
                    )
                }}
            </Route>
            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;