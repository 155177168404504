import MainMenuGuest from "./main-menu-guest";
import MainMenuApp from "./main-menu-app";

import "./main-menu.scss";

const MainMenu = {
    Guest : MainMenuGuest,
    App : MainMenuApp
}

export default MainMenu;