import React from "react";
import { useQuery } from "@apollo/client";

import { GET_LOCATION } from "graphql/query/location-gql";

import Location from "components/location";
import { VisitCheckin } from "components/visit";
import { Loader } from "components/request-result";
import { decode } from "utils";


const LocationPage = ({ history, locationInfo}) => {

    const [ locationType, locationId ]= decode.base64(locationInfo.slug).split(":");

    const { data : { location } = { }, loading } = useQuery( GET_LOCATION, {
        variables: {
            id: decode.hex( locationId )
        }
    });

    if(!loading && !location){
        history.replace("/404");

        return null;
    }

   const checkin = locationInfo.state && locationInfo.state.checkin;

    return(
        <div className="page location-page">
            <div className="container" >
                <div className="block sml">
                    { loading && <Loader type="block" /> }
                    { location  && !checkin &&
                        <Location
                            location={ location }
                            type={ locationType }
                            locationPath={ locationInfo.path }
                            locationState={ locationInfo.state }
                        />
                    }
                    { location  && checkin &&
                        <VisitCheckin
                            location={ location }
                            locationInfo={ locationInfo }
                        />
                    }
                </div>
            </div>
        </div>
    )
};

export default LocationPage;