import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            first_name
            last_name
            email
            phone
            language_slug
            status
            role
            avatar
            updated_at
            created_at
        }
    }
`;