import React from 'react';
import Icon from '@ant-design/icons';


const ChefSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 32 32" strokeWidth="1" >
        <path d="M16.126.001l.41.017.328.028.274.035.334.055.069.013c.104.02.207.043.31.067 1.025.243 2.012.69 2.895 1.342l.304.237c.886.72 1.616 1.629 2.13 2.67l.171.374.1.243.082.221.046-.014c.41-.127.834-.213 1.267-.256l.325-.025L25.5 5c3.59 0 6.5 2.91 6.5 6.5 0 3.276-2.423 5.986-5.575 6.435l-.232.028-.193.017V32H6V17.98l-.193-.017c-3.108-.329-5.563-2.85-5.79-5.987l-.013-.243L0 11.5C0 7.91 2.91 5 6.5 5c.55 0 1.09.068 1.61.201l.356.102.11-.289c.392-.975.969-1.851 1.685-2.588l.201-.2.184-.17.199-.174.235-.19.227-.171c.326-.238.666-.447 1.017-.629l.147-.073c.217-.107.438-.204.663-.29l.372-.131.264-.082c.155-.045.312-.086.47-.121l.333-.067.353-.056.304-.035.29-.023.176-.008.43-.005zM24 15.884l1.09.098c.136.012.272.018.41.018 2.485 0 4.5-2.015 4.5-4.5S27.985 7 25.5 7c-.532 0-1.049.092-1.534.268.209 2.285-.561 4.641-2.31 6.389l-1.413-1.414c1.426-1.427 1.984-3.393 1.674-5.241l-.048-.251-.056-.24-.066-.238c-.086-.287-.194-.569-.323-.843l-.138-.271c-.103-.193-.218-.381-.345-.564l-.198-.27c-.153-.197-.32-.387-.5-.568-.112-.111-.227-.218-.346-.32l-.183-.15-.199-.15-.088-.063-.089-.06-.093-.063-.092-.058.03.021-.201-.121c-.134-.077-.27-.148-.41-.214l-.211-.096-.218-.088c-.143-.055-.29-.104-.438-.148l-.323-.086-.212-.047-.155-.028-.238-.036-.233-.026-.128-.01-.183-.01L15.952 2l-.244.007-.252.018-.234.025-.238.036-.207.04-.16.035c-.16.037-.317.082-.471.132l-.29.101-.143.058c-.152.063-.301.132-.447.207l-.217.116-.199.118-.187.12-.177.124-.2.15-.184.151-.176.157c-.85.785-1.474 1.815-1.757 2.988l-.06.273L9.852 8.2 8.65 7.545C7.996 7.19 7.263 7 6.5 7 4.015 7 2 9.015 2 11.5S4.015 16 6.5 16l.206-.005.205-.013L8 15.883 7.999 26H12v-6h2v6h4v-6h2v6h2v2H7.998l.001 2H24V15.884z" transform="translate(-316 -182) translate(316 182)"/>
    </svg>
);

const ChefIcon = props => {
    return <Icon component={ ChefSvg } className={ props.className } />;
};


export default ChefIcon;