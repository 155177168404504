import React from "react";
import { Link } from "react-router-dom";

import { AvatarBlock } from "components/avatar";
import Icons from "components/icons";
import { QrCodeModal } from "components/service";
import { metaFields } from "utils";

import "./location.scss";



const Location = ({ location, locationPath, locationState }) => {


    const { normalize, getValue, getGroup } = metaFields;
    const meta = normalize( location.meta_fields );

    return(
        <div className="location">
            <div className="location-preview">
                <AvatarBlock
                    image={ location.logo }
                    size={ 120 }
                    className="location-logo"
                >
                    <p className="banner">
                        <img src={ location.preview_banner } alt={ location.title }/>
                    </p>
                    <div className="icons-set">
                        <Icons.Chef/>
                        <Icons.Cutlery/>
                        <Icons.Drink/>
                    </div>
                </AvatarBlock>
            </div>
            <div className="location-data">
                <h1>{ location.title }</h1>
                { getGroup(meta, "address").length &&
                    <div className="group address">
                        <span>{ getValue(meta, "location_street")  } </span>
                        <span>{ getValue(meta, "location_number")  }, </span>
                        <span>{ getValue(meta, "location_zip")  } </span>
                        <span>{ getValue(meta, "location_city")  }</span>
                    </div>
                }
                { getGroup(meta, "contacts").length &&
                    <div className="group contacts">
                        <h3>Contacts</h3>
                        { getValue(meta, "location_email") &&
                            <p>
                                <span>E-Mail: </span>
                                <a
                                    className="link-text"
                                    href={ getValue(meta, "location_email") }
                                    target="_blank" rel="noreferrer"
                                >
                                    { getValue(meta, "location_email") }
                                </a>
                            </p>
                        }
                        <p><span>Phone: </span>{ getValue(meta, "location_phone") }</p>
                    </div>
                }
                { getGroup(meta, "menus").length &&
                    <div className="group menus">
                        <h3>Menu</h3>
                        { getValue(meta, "menu_main") &&
                            <p>
                                <a
                                    className="link-text"
                                    href={ getValue(meta, "menu_main") }
                                    target="_blank" rel="noreferrer"
                                >
                                    Speisekarte
                                </a>
                            </p>
                        }
                        { getValue(meta, "menu_deserts") &&
                            <p>
                                <a
                                    className="link-text"
                                    href={ getValue(meta, "menu_deserts") }
                                    target="_blank" rel="noreferrer"
                                >
                                    Deserts
                                </a>
                            </p>
                        }
                        { getValue(meta, "menu_bar") &&
                            <p>
                                <a
                                    className="link-text"
                                    href={ getValue(meta, "menu_bar") }
                                    target="_blank" rel="noreferrer"
                                >
                                    Getränkekarte
                                </a>
                            </p>
                        }
                        { getValue(meta, "menu_vine") &&
                            <p>
                                <a
                                    className="link-text"
                                    href={ getValue(meta, "menu_vine") }
                                    target="_blank" rel="noreferrer"
                                >
                                    Weinkarte
                                </a>
                            </p>
                        }
                    </div>
                }
            </div>
            { locationState && locationState.scan ?
                <Link
                    className="link-button-primary"
                    to={{
                        pathname: locationPath,
                        state: {
                            checkin: true,
                            path: locationPath
                        }
                    }}
                >
                    jetzt einchecken
                </Link> :
                <QrCodeModal />
            }
        </div>
    )
};

export default Location;