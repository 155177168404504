import React from "react";
import { Button } from "antd";

import RegistrationLegalModal from "../registration-legal-modal";
import { useVarParam } from "components/use-hooks";


const RegistrationLegalNoticeStep = ({ registrationRequest, loading }) => {

    const guestParams = useVarParam("guest");
    const registration = guestParams.get("registration");

    return(
        <div className="registration-step legal">
            <h1>
                Deine Daten sind bei uns in guten Händen
            </h1>
            <p>
                Die angegebenen Daten im Loresta System werden, wenn nicht anderweitig eingewilligt,
                für die Auskunft an eine Gesundheitsbehörde im Falle eines Gesundheitsvorfalls im Rahmen der Corona
                Pandemie oder für die technische Bereitstellung von Funktionen verwendet. Informationen für die
                Behördenauskunft werden nach 4 Wochen aus dem Loresta System gelöscht.
            </p>
            <RegistrationLegalModal />
            <div className="step-actions">
                <Button
                    type="primary"
                    disabled={ loading }
                    loading={ loading }
                    onClick={ () => {
                        registrationRequest({
                            variables : {
                                input: {
                                    first_name: registration.firstName,
                                    last_name: registration.lastName,
                                    phone : registration.phone ?? "",
                                    email: registration.email,
                                    password: registration.password
                                }
                            },
                        })
                    }}
                >
                    Zustimmen und Absenden
                </Button>
            </div>
        </div>
    );
}

export default RegistrationLegalNoticeStep;