import React from 'react';
import Icon from '@ant-design/icons';


const PeoplesSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1" >
        <path d="M7.75 23h-1.5v-8.923c0-1.29-1.012-2.327-2.25-2.327-1.187 0-2.165.953-2.245 2.167l-.005.16V19H.25v-4.923c0-2.11 1.675-3.827 3.75-3.827 1.304 0 2.45.678 3.122 1.706C8.136 10.33 9.942 9.25 12 9.25c2.058 0 3.863 1.08 4.88 2.706.67-1.028 1.816-1.706 3.12-1.706 2.01 0 3.645 1.612 3.745 3.63l.005.197V19h-1.5v-4.923c0-1.29-1.012-2.327-2.25-2.327-1.187 0-2.165.953-2.245 2.167l-.005.16V23h-1.5v-8c0-2.347-1.903-4.25-4.25-4.25-2.278 0-4.138 1.793-4.245 4.044L7.75 15v8zM20 4c1.38 0 2.5 1.12 2.5 2.5S21.38 9 20 9s-2.5-1.12-2.5-2.5S18.62 4 20 4zM4 4c1.38 0 2.5 1.12 2.5 2.5S5.38 9 4 9 1.5 7.88 1.5 6.5 2.62 4 4 4zm8-3c1.933 0 3.5 1.567 3.5 3.5 0 1.869-1.464 3.395-3.308 3.495L12 8c-1.933 0-3.5-1.567-3.5-3.5S10.067 1 12 1zm8 4.5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-16 0c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm8-3c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z" transform="translate(-232 -266) translate(32 200) translate(200 66)"/>
    </svg>
);

const PeoplesIcon = props => {
    return <Icon component={ PeoplesSvg } className={ props.className } />;

};


export default PeoplesIcon;