import React from "react";
import { Button, Form, Input } from "antd";

import { FloatLabel } from "components/service";
import { useVarParam } from "components/use-hooks";


const RegistrationIntroduceStep = ({ formItemLayout, submitAction = () => {} }) => {

    const [ form ] = Form.useForm();
    const guestParams = useVarParam("guest");

    const registration = guestParams.get("registration");

    return (
        <div className="registration-step">
            <h1>
                Dein Name und deine Telefonnummer
            </h1>
            <Form
                className="registration-form"
                form={ form }
                onFinish ={(values) => {
                    guestParams.set({
                        registration : {
                            ...registration, ...values
                        }
                    })
                    submitAction();
                }}
            >
                <Form.Item
                    name="firstName"
                    {...formItemLayout}
                    initialValue={ registration.firstName ?? "" }
                    rules={[
                        {
                            required: true,
                            message: 'Please input your First name'
                        }
                    ]}
                >
                    <FloatLabel label="Vorname*">
                        <Input name="first_name" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    name="lastName"
                    initialValue={ registration.lastName ?? "" }
                    {...formItemLayout}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Last name'
                        }
                    ]}
                >
                    <FloatLabel label="Nachname*">
                        <Input name="last_name" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    name="phone"
                    initialValue={ registration.phone ?? "" }
                    {...formItemLayout}
                >
                    <FloatLabel label="Telefonnummer">
                        <Input name="phone_number" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Weiter
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default RegistrationIntroduceStep;