import MainMenuGuestBar from "./main-menu-guest-bar";
import MainMenuAppBar from "./main-menu-app-bar";

import "./main-menu-bar.scss";


const MainMenuBar = {
    Guest : MainMenuGuestBar,
    App: MainMenuAppBar
}


export default MainMenuBar;