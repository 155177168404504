import React from "react";

import { AvatarBlock } from "components/avatar";
import Icons from "components/icons";
import { metaFields } from "utils";


const VisitListItem = ({ visit } ) => {

    const { normalize, getValue, getGroup } = metaFields;
    const meta = normalize( visit.location.meta_fields );

    const [ date, time ] = visit.created_at.split(" ");


    return(
        <div className="visit-list-item">
            <AvatarBlock
                image={ visit.location.logo }
                size={ 90 }
                className="visit-list-logo"
            >
                <p className="date text-type3">{ date }</p>
                <h3>{ visit.location.title }</h3>
                { getGroup(meta, "address").length &&
                    <p className="address text-type3-light">
                        <span>{ getValue(meta, "location_street")  } </span>
                        <span>{ getValue(meta, "location_number")  }, </span>
                        <span>{ getValue(meta, "location_zip")  } </span>
                        <span>{ getValue(meta, "location_city")  }</span>
                    </p>
                }
                <p className="details">
                    <span><Icons.Clock />{ time }</span>
                    <span><Icons.Peoples />{ visit.number_guests }</span>
                </p>
            </AvatarBlock>
        </div>
    );
}

export default VisitListItem;