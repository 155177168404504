import VisitCheckinIntroduceStep from "./visit-checkin-introduce-step";
import VisitCheckinContactStep from "./visit-checkin-contact-step";
import VisitCheckinGuestsStep from "./visit-checkin-guests-step";
import VisitCheckinLegalStep from "./visit-checkin-legal-step";
import VisitCheckinSuccessStep from "./visit-checkin-success-step";


const VisitCheckinSteps = {
    Introduce : VisitCheckinIntroduceStep,
    Contact : VisitCheckinContactStep,
    Guests : VisitCheckinGuestsStep,
    LegalNotice : VisitCheckinLegalStep,
    Success :  VisitCheckinSuccessStep
}

export default VisitCheckinSteps;