import React from 'react';
import Icon from '@ant-design/icons';


const CrossSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1" >
        <path d="M16.95 5.636l1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95 4.95-4.95z" transform="translate(-335 -86) translate(335 86)"/>
    </svg>
);

const CrossIcon = props => {
    return <Icon component={ CrossSvg } className={ props.className } />;

};


export default CrossIcon;