import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { IS_LOGGED_IN } from "graphql/query/local-store-gql";

import { QrCodeModal } from "components/service";
import { cloneChildren } from "utils";


const VisitCheckinAuth = ({ locationInfo, ...params }) => {

    const { data } = useQuery( IS_LOGGED_IN );

    if(data.isLoggedIn || locationInfo.state.noRegistration){

        return (
            cloneChildren(
                params.children, { userType: data.isLoggedIn ? "app" : "guest" }
            )
        );
    }

    return(
        <div className="block auth">
            <div className="row">
                <h1>Willkommen bei Loresta – der sicheren digitalen Schnittstelle zwischen Gästen und Lokalen</h1>
            </div>
            <div className="row">
                <Link to="/registration/" className="link-button-primary">Bei loresta registrieren</Link>
            </div>
            <div className="row">
                <Link
                    to={{
                        pathname : locationInfo.path,
                        state: {
                            checkin: true,
                            path: locationInfo.path,
                            noRegistration: true
                        }
                    }}
                    className="link-button-default"
                >
                    Weiter ohne Registrierung
                </Link>
            </div>
            <div className="row bordered">
                <p>Du hast bereits einen Account?</p>
                <Link
                    to={{
                        pathname : "/login/",
                        state: {
                            checkin: true,
                            path: locationInfo.path,
                            saveState: true
                        }
                    }}
                    className="link-text-bold"
                >
                    Jetzt anmelden und einchecken
                </Link>
            </div>
            <QrCodeModal />
        </div>
    );
};

export default VisitCheckinAuth;