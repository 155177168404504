import { HomeAppPage, HomeGuestPage } from "./home";
import { LoginPage, RegistrationPage, ForgotPasswordPage } from "./authorization";
import ProfilePage from "./profile/profile-page";
import LocationPage from  "./location/location-page";
import VisitsPage from "./visits/visits-page";
import Page404 from "./404/404-page";

import "./pages.scss";


export const Pages = {
    HomeAppPage,
    ProfilePage,
    LocationPage,
    VisitsPage,
    Page404
};

export const GuestPages = {
    HomeGuestPage,
    LoginPage,
    RegistrationPage,
    ForgotPasswordPage,
    LocationPage,
    Page404
}