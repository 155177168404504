import React from 'react';
import Icon from '@ant-design/icons';


const CalendarSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1" >
        <path d="M6 1v2h12V1h1.5v2H23c.552 0 1 .448 1 1v17H0V4c0-.552.448-1 1-1h3.5V1H6zm16.5 9h-21v9.5h21V10zm-18-5.5h-3v4h21v-4h-3V6H18V4.5H6V6H4.5V4.5z" transform="translate(-40 -314) translate(40 314)"/>
    </svg>
);

const CalendarIcon = props => {
    return <Icon component={ CalendarSvg } className={ props.className } />;
};


export default CalendarIcon;