import UserChangePasswordForm from "./user-change-password-form";
import UserProfileForm from "./user-profile-form";

import "./user-forms.scss";


const Forms = {
    ChangePassword : UserChangePasswordForm,
    Profile : UserProfileForm

};

export default Forms;