import React from "react";
import { Button, Form, Input } from "antd";

import { FloatLabel } from "components/service";


const VisitCheckinIntroduceStep = ({ formItemLayout, varParams, submitAction = () => {} }) => {

    const [ form ] = Form.useForm();

    const checkin = varParams.get("checkin");

    const {
        firstName = "",
        lastName = ""
    } = checkin;

    return (
        <div className="visit-checkin-step">
            <h1>
                Nenne uns deinen Namen zum Einchecken
            </h1>
            <Form
                className="checkin-form"
                form={ form }
                onFinish ={(values) => {
                    varParams.set({
                        checkin : {
                            ...checkin, ...values
                        }
                    })
                    submitAction();
                }}
            >
                <Form.Item
                    name="firstName"
                    {...formItemLayout}
                    initialValue={ firstName }
                    rules={[
                        {
                            required: true,
                            message: 'Please input your First name'
                        }
                    ]}
                >
                    <FloatLabel label="Vorname*">
                        <Input name="first_name" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    name="lastName"
                    initialValue={ lastName }
                    {...formItemLayout}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Last name'
                        }
                    ]}
                >
                    <FloatLabel label="Nachname*">
                        <Input name="last_name" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Weiter
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default VisitCheckinIntroduceStep;