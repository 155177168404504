import React from 'react';
import Icon from '@ant-design/icons';


const ClockSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1" >
        <path d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 1.5c-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5 4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5zm.75 2v6.31l-2.816 2.817-1.06-1.06 2.376-2.379V6.5h1.5z" transform="translate(-136 -266) translate(32 200) translate(104 66)"/>
    </svg>
);

const ClockIcon = props => {
    return <Icon component={ ClockSvg } className={ props.className } />;

};


export default ClockIcon;