import React from "react";
import { Button, Drawer } from "antd";

import Icons from "components/icons";

import "./menu-drawer.scss";


const MenuDrawer = ({ varParam, children }) => {

    return(
        <>
            <Button
                className="icon"
                onClick={ () => varParam.set({ "menuDrawerOn" : true }) }
            >
                <Icons.Menu />
            </Button>
            <Drawer
                closeIcon={ <Icons.Cross /> }
                className={ "menu-drawer" }
                placement="bottom"
                onClose={ () => varParam.set({ "menuDrawerOn" : false }) }
                visible={ varParam.get("menuDrawerOn") }
            >
                <div className="container">
                    {   children.map( (item) => {
                            return(
                                React.cloneElement( item, {
                                    action : () => varParam.set({ "menuDrawerOn" : false })

                                })
                            )
                        })
                    }
                </div>
            </Drawer>
        </>
    );
}

export default MenuDrawer;