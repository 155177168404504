import React from 'react';
import Icon from '@ant-design/icons';


const EmailSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 32 32" strokeWidth="1" >
        <path d="M32 7v18H8V7h24zm-2 3.538l-8.657 7.842c-.762.69-1.924.69-2.686 0L10 10.54V23h20V10.538zM6 19v2H3v-2h3zm0-4v2H1v-2h5zm22.72-6H11.28L20 16.898 28.72 9zM6 11v2H0v-2h6z" transform="translate(-40.000000, -320.000000) translate(40.000000, 320.000000)"/>
    </svg>
);

const EmailIcon = props => {
    return <Icon component={ EmailSvg } className={ props.className } />;

};


export default EmailIcon;