import React from "react";
import { useQuery } from "@apollo/client";
import { isIOS } from 'react-device-detect';

import { GET_GUEST_PARAMS } from "graphql/query/local-store-gql";

import { MainMenuBar } from "components/menus";
import { GuestRoutes } from "components/routes";

import "./guest-app.scss";


const GuestApp = () => {

    const { data : { guestParams } } = useQuery( GET_GUEST_PARAMS );

    return(
        <div className={ `guest-app ${ isIOS ? "ios" : "" } ${ guestParams.appBlockClass.join(" ") }`}>
            <MainMenuBar.Guest />
            <main>
                <GuestRoutes />
            </main>
        </div>
    );
};

export default GuestApp;