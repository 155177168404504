import React from "react";


const cloneChildren = ( children, props ) => {

    if(!children){
        return null;
    }


    if(Array.isArray(children)){

        return children.map(
            ( child, index ) => {

                if (typeof child.type === "function" || typeof child.type === "object") {
                    return React.cloneElement(
                        child,
                        {
                            key: index,
                            ...props
                        }
                    )
                }

                return React.cloneElement(child, {
                    key: index,
                    ...props
                });
            }
        );

    }

     return  typeof children === "function" || typeof children === "object"  ?  React.cloneElement( children, props) : children;
}


export default cloneChildren;