import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import { useLogout } from "components/use-hooks";


const MainMenuApp = ( { action } ) => {

    const { logout } = useLogout();

    return(
        <nav className="main-menu-wrap">
            <ul className="main-menu">
                <li>
                    <a href="https://loresta.de/faq" target="_blank" rel="noreferrer">FAQ</a>
                </li>
                <li>
                    <Link to="/visits/" onClick={ action }>Besuche</Link>
                </li>
                <li>
                    <Link to="/profile/" onClick={ action }>Profil</Link>
                </li>
                <li>
                    <Button
                        onClick={ () => logout({ redirectRoute: "/login/" }) }
                        type="link"
                    >
                        Logout
                    </Button>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenuApp;
