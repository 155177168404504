import React from 'react';
import Icon from '@ant-design/icons';


const QrSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 512 512" strokeWidth="1" >
        <path d="M458.667,0H53.333C23.893,0.035,0.035,23.893,0,53.333v405.333C0.035,488.107,23.893,511.965,53.333,512h405.333    c29.441-0.035,53.298-23.893,53.333-53.333V53.333C511.965,23.893,488.107,0.035,458.667,0z M490.667,458.667    c0,17.673-14.327,32-32,32H53.333c-17.673,0-32-14.327-32-32V53.333c0-17.673,14.327-32,32-32h405.333c17.673,0,32,14.327,32,32    V458.667z"/>
        <path d="M170.667,85.333H85.333c-5.891,0-10.667,4.776-10.667,10.667v85.333c0,5.891,4.776,10.667,10.667,10.667h85.333    c5.891,0,10.667-4.776,10.667-10.667V96C181.333,90.109,176.558,85.333,170.667,85.333z M160,170.667H96v-64h64V170.667z"/>
        <path d="M170.667,320H85.333c-5.891,0-10.667,4.776-10.667,10.667V416c0,5.891,4.776,10.667,10.667,10.667h85.333    c5.891,0,10.667-4.776,10.667-10.667v-85.333C181.333,324.776,176.558,320,170.667,320z M160,405.333H96v-64h64V405.333z"/>
        <path d="M426.667,85.333h-85.333c-5.891,0-10.667,4.776-10.667,10.667v85.333c0,5.891,4.776,10.667,10.667,10.667h85.333    c5.891,0,10.667-4.776,10.667-10.667V96C437.333,90.109,432.558,85.333,426.667,85.333z M416,170.667h-64v-64h64V170.667z"/>
        <rect x="117.333" y="128" width="21.333" height="21.333"/>
        <rect x="117.333" y="362.667" width="21.333" height="21.333"/>
        <rect x="373.333" y="128" width="21.333" height="21.333"/>
        <path d="M437.333,352h-128v21.333h117.333v32H352v21.333h85.333c5.891,0,10.667-4.776,10.667-10.667v-53.333    C448,356.776,443.224,352,437.333,352z"/>
        <rect x="266.667" y="352" width="21.333" height="74.667"/>
        <rect x="309.333" y="405.333" width="21.333" height="21.333"/>
        <path d="M298.667,85.333H256c-5.891,0-10.667,4.776-10.667,10.667v42.667H224V85.333h-21.333v64    c0,5.891,4.776,10.667,10.667,10.667H256c5.891,0,10.667-4.776,10.667-10.667v-42.667H288V160h21.333V96    C309.333,90.109,304.558,85.333,298.667,85.333z"/>
        <path d="M309.333,256v-64H288v74.667c0,5.891,4.776,10.667,10.667,10.667h138.667V256H309.333z"/>
        <path d="M202.667,192v21.333h-32v21.333h42.667c5.891,0,10.667-4.776,10.667-10.667v-32H202.667z"/>
        <rect x="74.667" y="213.333" width="32" height="21.333"/>
        <rect x="128" y="213.333" width="21.333" height="21.333"/>
        <rect x="416" y="213.333" width="21.333" height="21.333"/>
        <rect x="330.667" y="213.333" width="64" height="21.333"/>
        <path d="M224,309.333c-5.891,0-10.667,4.776-10.667,10.667v106.667h21.333v-96H448v-21.333H224z"/>
        <rect x="245.333" y="192" width="21.333" height="53.333"/>
        <path d="M192,256H74.667v21.333h106.667v21.333h21.333v-32C202.667,260.776,197.891,256,192,256z"/>
        <rect x="245.333" y="266.667" width="21.333" height="21.333"/>
    </svg>
);

const QrIcon = props => {
    return <Icon component={ QrSvg } className={ props.className } />;

};


export default QrIcon;