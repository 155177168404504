import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { VISIT_CHECKIN } from "graphql/mutation/visit-gql";

import VisitCheckinSteps from "./steps";
import { AvatarBlock } from "components/avatar";
import { StepsNav } from "components/service";
import { useVarParam } from "components/use-hooks";
import { errorNotification } from "components/request-result";


const VisitCheckinForm = ({ userType = "guest", location, locationPath }) => {

    const [ success, setSuccess ] = useState(false);
    const varParams = useVarParam(userType);

    const [ checkinRequest, { loading } ] = useMutation(
        VISIT_CHECKIN,
        {
            update() {

                setSuccess(true);

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const stepNavSettings = {
        varParams,
        stateName :"checkin",
        inMainHeader : true ,
        backOnFirstStep:  false
    }

    const stepSettings = {
        varParams,
        formItemLayout : {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        }
    };

    return(
        <div className="visit-checkin-form">
            { success ?
                <VisitCheckinSteps.Success
                    success={ success }
                    varParams={ varParams }
                    location={ location }
                    locationPath={ locationPath }
                    userType={ userType }
                />:
                <>
                    <AvatarBlock
                        image={ location.logo }
                        size={ 90 }
                        className="visit-checkin-logo"
                    >
                        <p className="text-mono-bold">{ location.title }</p>
                    </AvatarBlock>
                    { userType === "guest" ?
                        <StepsNav
                            { ...stepNavSettings }
                        >
                            < VisitCheckinSteps.Introduce
                                { ...stepSettings }
                            />
                            <VisitCheckinSteps.Contact
                                { ...stepSettings }
                            />
                            <VisitCheckinSteps.Guests
                                { ...stepSettings }
                            />
                            <VisitCheckinSteps.LegalNotice
                                checkinRequest={ checkinRequest }
                                varParams={ varParams }
                                locationId={ location.id }
                                loading={ loading }
                            />
                        </StepsNav> :
                        <StepsNav
                            {  ...stepNavSettings }
                        >
                            <VisitCheckinSteps.Guests
                                { ...stepSettings }
                            />
                            <VisitCheckinSteps.LegalNotice
                                checkinRequest={ checkinRequest }
                                varParams={ varParams }
                                locationId={ location.id }
                                loading={ loading }
                            />
                        </StepsNav>
                    }
                </>

            }
        </div>
    );
};

export default VisitCheckinForm;