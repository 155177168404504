import React, { useState } from "react";
import {Button, Form, Input} from "antd";

import Icons from "components/icons";
import { FloatLabel } from "components/service";


const VisitCheckinGuestsStep = ({ varParams, submitAction = () => {} }) => {

    const [ form ] = Form.useForm();

    const checkin = varParams.get("checkin");
    const [ guestCount, setGuestCount ] = useState(checkin.guestCount ?? 1);

    const date = new Date();
    const time = date.toLocaleTimeString().split(":")

    return(
        <div className="visit-checkin-step guests">
            <h1>
                Wie viele Personen seid ihr an eurem Tisch?
            </h1>
            <Form
                className="checkin-form"
                form={ form }
                onFinish ={() => {
                    varParams.set({
                        checkin : {
                            ...checkin, guestCount
                        }
                    })
                    submitAction();
                }}
            >
                <div className="row">
                    <Icons.Calendar />
                    { date.toLocaleDateString() }
                </div>
                <div className="row">
                    <Icons.Clock />
                    { time[0] } : { time[1] }
                </div>
                <div className="row">
                    <Icons.Peoples />
                    <div className="guest-input">
                        <Button
                            className="icon rounded"
                            onClick={ () => {
                                if(guestCount > 1) {
                                    setGuestCount(guestCount - 1)
                                }
                            }}
                        >
                            -
                        </Button>
                        <FloatLabel value={ guestCount }>
                            <Input name="guest-count" />
                        </FloatLabel>
                        <Button
                            className="icon rounded"
                            onClick={ () => {
                                if(guestCount < 99) {
                                    setGuestCount(guestCount + 1)
                                }
                            }}
                        >
                            +
                        </Button>
                    </div>
                </div>
                <div className="form-actions">
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Weiter
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default VisitCheckinGuestsStep;