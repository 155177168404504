import React, { useEffect } from "react";
import { Button } from "antd";

import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";


const RegistrationSuccessStep = ({ success }) => {

    const guestParams = useVarParam("guest");

    useEffect(() => {
        return () => {

            guestParams.set({
                registration : {}
            })
        }
    }, [success, guestParams]);

    return(
        <div className="registration-step success">
            <h1>Vielen Dank!</h1>
            <p>Wir haben dir eine E-Mail mit Aktivierungslink geschickt. </p>
            <div className="icons-set">
                <Icons.Document />
                <Icons.Email />
                <Icons.Flag />
            </div>
            <div className="row bordered-top">
                <p>Du hast keine E-Mail bekommen?</p>
                <Button type="link">
                    Erneute E-Mail anfordern
                </Button>
            </div>
        </div>
    )
};

export default RegistrationSuccessStep;