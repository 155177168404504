import React from "react";
import { Switch, Route } from 'react-router-dom';

import { GuestPages } from "components/pages";


const GuestRoutes = () => {

    return(
        <Switch>
            <Route path="/" exact>
                <GuestPages.HomeGuestPage />
            </Route>
            <Route path="/registration/" >
                <GuestPages.RegistrationPage />
            </Route>
            <Route path="/login/" exact>
                <GuestPages.LoginPage />
            </Route>
            <Route path="/forgot-password/">
                <GuestPages.ForgotPasswordPage />
            </Route>
            <Route path="/location/:locationSlug" exact>
                { ({ match, history, location }) => {
                    return (
                        <GuestPages.LocationPage
                            history={ history }
                            locationInfo={{
                                slug : match.params.locationSlug,
                                state: location.state,
                                path : location.pathname
                            }}
                        />
                    )
                }}
            </Route>
            <Route path="*">
                <GuestPages.Page404 />
            </Route>
        </Switch>
    );
};

export default GuestRoutes;