import React from "react";
import {Button, Form, Checkbox} from "antd";


const VisitCheckinLegalStep = ({ varParams, loading, locationId, checkinRequest }) => {

    const [ form ] = Form.useForm();

    const checkin = varParams.get("checkin");

    return(
        <div className="visit-checkin-step legal">
            <h1>
                Wie viele Personen seid ihr an eurem Tisch?
            </h1>
            <Form
                className="checkin-form"
                form={ form }
                onFinish ={() => {

                    checkinRequest({
                        variables : {
                            input: {
                                location_id: locationId,
                                first_name: checkin.firstName,
                                last_name: checkin.lastName,
                                phone : checkin.phone ?? "",
                                email: checkin.email,
                                number_guests: checkin.guestCount
                            }
                        },
                    })
                }}
            >
                <Form.Item
                    className="legal-block"
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) => {

                                if(value) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(new Error('You should to agree for the checkin '))
                                }
                            },

                        },
                    ]}
                >
                    <Checkbox>
                        Persönlicher Kontakt zu deinem Restraurant, (Rabatt-)Aktionen, Informationen, uvm.
                        <p className="text-type3-light">Ich stimme zu, dass Bestes Restaurant meine Kontaktdaten für den persönlichen Kontakt verwenden und mich für entsprechende Angebote und Informationen kontaktieren darf. Diese Einwilligung kann ich jederzeit in meinem Profileinstellungen wiederrufen.</p>
                    </Checkbox>
                </Form.Item>
                <div className="form-actions">
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={ loading }
                        loading={ loading }
                    >
                        Absenden
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default VisitCheckinLegalStep;