import React, { useState }  from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { ACTIVATE_ACCOUNT } from "graphql/mutation/user-gql";

import { Loader } from "components/request-result";

import "./auth-confirm-account.scss";


const AuthConfirmAccount = ({ token }) => {

    const [ requestMessage, setRequestMessage ] = useState({});

    const [activationRequest, { loading, error, called }] = useMutation( ACTIVATE_ACCOUNT, {
            update(cache, { data : { userActivate } }) {
                setRequestMessage({
                    status: "success",
                    label: userActivate.label,
                    message: userActivate.message
                });
            }
        }
    );

    if(!called && token){

        activationRequest({
            variables : { token }
        }).catch((error) => {
            setRequestMessage({
                status: "error",
                label: error.graphQLErrors[0].message,
                message: error.graphQLErrors[0].extensions.reason
            });
        });
    }

    if(requestMessage.status === undefined){
        setRequestMessage({
            status: "error",
            label: "Activation token error",
            message: "Token not provided, please open or copy all link from activation E-Mail"
        });
    }

    return(
        <>
            <div className={`activation-account ${loading ? "loading" : ""}` }>
                { loading ?
                    <>
                        <Loader blockClass={"color-primary"} />
                        <p >Please wait, we are activate your account</p>
                    </>
                    :
                    <>
                        { !error && token &&
                        <>
                            <h1>Your account activated</h1>
                            <p>Please log in to enter Loresta</p>
                            <p>
                                <Link to="/login/" className="link-button-primary">
                                    <span>LOG IN</span>
                                </Link>
                            </p>
                        </>
                        }
                        { (error || !token) &&
                        <>
                            <h1>{ requestMessage.label }</h1>
                            <p>{ requestMessage.message }</p>
                            <p>
                                <Link to="/" className="link-active text-bold">To the sign in page</Link>
                            </p>
                        </>
                        }
                    </>
                }
            </div>
        </>
    );
}


export default AuthConfirmAccount;