import React from 'react';
import { useQuery } from "@apollo/client";
import { isIOS } from 'react-device-detect';

import { GET_APP_PARAMS } from "graphql/query/local-store-gql";

import { MainMenuBar } from "components/menus";
import { AppRoutes } from "components/routes";
import { Suspense } from "components/request-result";
import { useMe } from "components/use-hooks";

import './app.scss';


const App = () => {

    const { loading, error } = useMe("cache-and-network", true),
        { data : { appParams } } = useQuery( GET_APP_PARAMS );

    return (
        <div className={ `app ${ isIOS ? "ios" : "" } ${ appParams.appBlockClass.join(" ") }`}>
            <MainMenuBar.App />
            <Suspense type="block" state={ { loading, error } }>
                <main>
                    <AppRoutes />
                </main>
            </Suspense>
        </div>
    );
};

export default App;