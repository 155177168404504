import React from "react";

import { VisitList } from "components/visit";


const VisitsPage = () => {


    return(
        <div className="page visits-page">
            <div className="container" >
                <div className="block sml">
                    <div className="row">
                        <h1>Deine Besuchshistorie</h1>
                    </div>
                    <VisitList />
                </div>
            </div>
        </div>
    )
};

export default VisitsPage;