import React from "react";
import { useHistory } from "react-router-dom";
import QrReader from "react-qr-reader";
import { Button } from "antd";

import { ModalStandard } from "components/service";
import Icons from "components/icons";


export const QrCodeReader = ({ onSuccess, action }) => {

    const history = useHistory();

    return(
        <div className="qr-code-reader-wrap">
            <QrReader
                delay={ 300 }
                onError={ ( err ) => console.log(err) }
                onScan={ ( data  ) => {
                    if(data){
                        const url = new URL(data);
                        history.push(url.pathname, { scan : true });

                        action();
                        onSuccess();
                    }
                }}
            />
        </div>
    )
}

export const QrCodeModal = ( { successAction = () => {} } ) => {

    const QrButton = ({ onClick }) => {

        return(
            <div className="qr-code-modal-button-wrap">
                <Button type="primary" className="icon rounded-primary" onClick={ onClick }>
                    <Icons.Qr />
                </Button>
                <p>QR Code scannen zum Einchecken</p>
            </div>
        )
    };

    const CloseButton = ( { action } ) => (
        <div className="close-button-wrap">
            <Button type="primary" onClick={ action }>Abschließen</Button>
        </div>
    );

    return(
        <ModalStandard
            extraClass="modal-dark qr-code-modal"
            modalButton={ <QrButton /> }
        >
            <QrCodeReader onSuccess={ successAction } />
            <CloseButton />
        </ModalStandard>
    );
}

