import React from 'react';
import Icon from '@ant-design/icons';


const MenuSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1" >
        <path d="M20 17v2H4v-2h16zm0-6v2H4v-2h16zm0-6v2H4V5h16z" transform="translate(-331.000000, -86.000000) translate(331.000000, 86.000000)"/>
    </svg>
);

const MenuIcon = props => {
    return <Icon component={ MenuSvg } className={ props.className } />;

};


export default MenuIcon;