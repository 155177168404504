import React from "react";
import { Link } from "react-router-dom";
import { QrCodeModal } from "components/service";


const HomeGuestPage = () => {



    return(
        <div className="page">
            <div className="container" >
                <div className="block">
                    <div className="row">
                        <h1>Willkommen bei Loresta – der sicheren digitalen Schnittstelle zwischen Gästen und Lokalen</h1>
                    </div>
                    <div className="row">
                        <Link to="/registration/" className="link-button-primary">Bei loresta registrieren</Link>
                    </div>
                    <div className="row bordered">
                        <p>Du hast bereits einen Account?</p>
                        <Link to="/login/" className="link-text-bold">
                            Jetzt anmelden und einchecken
                        </Link>
                    </div>
                    <QrCodeModal />
                </div>
            </div>
        </div>
    )
};

export default HomeGuestPage;